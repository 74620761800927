// set default configurations
export default {
  debug: false,
  showZoomControl: true,
  showLayerControl: true,
  showAttribution: true,
  showTooltips: true,
  showPopups: true,
  showAnimation: true,
  minPlotRefreshTime: 0.75,

  ccFillColor: "#5aadad",
  botnetFillColor: "#eec216",

  _style: "threat-map",
  _isWidget: false
};
