export const popupTemplate = `<div class="map-marker-tooltip-header text-center">{bot_count} Botnet{plural} Active</div>
<div class="map-marker-tooltip-content">
  <div class="loader"></div>
  <div class="map-marker-slider" id="map-marker-slider">
  {slides}
  </div>
  <!-- 
  <div class="map-marker-summary">
    <table class="table">
      <tr>
        <td><span class="label">Total Bot Count</span><br><span class="field field-color">{full_count}</span></td>
      </tr>
    </table>
  </div>
  -->
</div>`;

export const slideTemplate = `<div class="map-marker-slide">
<table class="table">
  <tr class="borderless">
    <td><img src="https://www.spamhaustech.com/threat-map/img/invaders/{icon}" class="invader"></td>
    <td><span class="label">Name</span><br><span class="field">{name}</span></td>
  </tr>
  <tr class="borderless">
    <td><!-- reserved for category name and switched --></td>
    <td><span class="label">Active Bots</span><br><span class="field field-color">{count}</span></td>
  </tr>
</table>
</div>`;
