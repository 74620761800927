
import "../styles/index.scss";
import "./jquery-loader"; // phasing jquery out

import logoIcon from "../images/logo_spamhaus_technology_long_white.png";
let logoImg = document.getElementById("logo");
logoImg.src = logoIcon;

import "slick-carousel";

import map from "./map.js";
import defaultConfig from "./configuration";

let config = defaultConfig;
config.mapElement = "map";

/**
 * Bad module
 * As a node_modules folder module although
 * styles are imported from separate file in styles folder
 */
import * as FullScreen from "leaflet.fullscreen";

map(config, $, window);

import "./clock.js";
import "./tables.js";
import "./counter.js";
