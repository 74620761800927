(function() {
  function ready(fn) {
    if (document.readyState != "loading") {
      fn();
    } else {
      document.addEventListener("DOMContentLoaded", fn);
    }
  }

  const updateTime = () => {
    let elTime = document.getElementById("js-updatetime");
    elTime.textContent = new Date().toLocaleTimeString([], { hour12: false });
  };

  const updateDate = () => {
    let elDate = document.getElementById("js-updatedate");
    elDate.textContent = new Date().toLocaleDateString([], {
      year: "numeric",
      month: "short",
      day: "numeric"
    });
  };

  ready(() => {
    updateDate();
    setInterval(updateDate, 1000 * 60 * 60);

    updateTime();
    setInterval(updateTime, 1000);
  });
})();
