import { CountUp } from "countup.js";

(function () {
  /**
   *  Botnet counter count up
   */
  let numAnim;
  const startBotCounter = (start, lasthour, previoushour) => {
    let tenSecondValue = lasthour * 0.0027,
      stepValue = start,
      direction = lasthour > previoushour;

    numAnim = new CountUp("js-zrd-count", start, {
      startVal: start,
      duration: 10,
      useEasing: true,
      useGrouping: true,
      separator: ",",
    });

    const updateBotCounter = () => {
      if (direction) stepValue += tenSecondValue;
      else stepValue -= tenSecondValue;
      numAnim.update(stepValue);
    };

    setInterval(updateBotCounter, 10000);
  };

  let activityChart;
  const drawTrendline = (series) => {
    activityChart = new Chart("activity-chart", {
      data: {
        labels: [
          24,
          23,
          22,
          21,
          20,
          19,
          18,
          17,
          16,
          15,
          14,
          13,
          12,
          11,
          10,
          9,
          8,
          7,
          6,
          5,
          4,
          3,
          2,
          1,
        ],
        datasets: [
          {
            label: "Botnet activity 24 hrs",
            backgroundColor: "#fff",
            borderColor: "#fff",
            data: series,
            fill: false,
          },
        ],
      },
      type: "bar",
      options: {
        layout: { padding: { left: 10, right: 10, top: 0, bottom: 10 } },
        responsive: true,
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        elements: { point: { radius: 0 }, line: { tension: 0 } },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                fontColor: "#fff",
                fontSize: 10,
                labelString: "Last 24 hrs hourly activity",
              },
              ticks: {
                display: false,
                fontColor: "#fff",
                fontSize: 6,
                callback: function (value, index, values) {
                  return value % 4 === 0 ? value : "";
                },
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Value",
              },
              ticks: {
                display: false,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
      },
    });
  };

  const init = function () {
    // get feed
    $.get(DATA_URL + "botcounter/", {}, function (res, resp) {
      startBotCounter(res.total, res.hourly[23], res.hourly[22]);

      var hourly = res.hourly.map(function (x) {
        return parseInt(x, 10);
      });
      drawTrendline(hourly);
    });
  };

  $(function () {
    init();
  });
})();
