import { popupTemplate, slideTemplate } from "./templates/popup";
import { widgetFooter } from './templates/widget';

const mapInstance = function (config, $, window) {
  let loadCount = 0;
  let plotCount = 0;

  /**
   * Things are so complex now, and can be
   * manipulated dynamically, we need
   * to manage the current state
   */
  let state = {};

  /**
   * Set maximum bounds for the world, so no
   * scrolling outside of this
   */
  const boundsSouthWest = L.latLng(-90, -180),
    boundsNorthEast = L.latLng(90, 180);
  const boundsLimit = L.latLngBounds(boundsSouthWest, boundsNorthEast);

  /**
   * Mobile settings and overrides
   * Mobile behaviour has a few limitations
   */
  const isMobile = L.Browser.mobile;
  const mobileMarkerLoadLimit = 20;

  /**
   * Map set up
   */

  const zoomLevel = isMobile ? 1 : 2.5;
  const markerMaxSize = isMobile ? 4 : 25;
  const markerDefaultSize = isMobile ? 2 : 4;
  const defaultMapCenter = isMobile ? [32.3739, 8.5456] : [32.3739, 8.5456];

  /**
   * Marker threshold at which we slow requests
   * No. of markers then slow requests for more
   */
  const markerSlowThreshold = isMobile ? 250 : 650;

  /**
   * The default minimum time between reloading dots (convert from seconds to js microseconds)
   */
  const minPlotRefreshTime = config.minPlotRefreshTime * 1000;
  const minBusyPlotRefreshTime = minPlotRefreshTime * 4;
  /**
   * Default DOM element for attaching map
   */
  const mapElement = config.mapElement || "spamhaus-threat-map";

  /**
   * Default lat/long center point for map
   */
  const mapCenter = config.mapCenter || defaultMapCenter;

  if (config.debug) console.log("Config: ", config);
  if (config.debug) console.log("Mobile targeting: " + isMobile);

  /**
   * If there is no map container, bail
   */
  if (!document.getElementById(mapElement)) return false;


  /**
   * Inject widget footer element if 
   * map is running as a widget
   */
  if (config._isWidget) {
    let footer = document.createElement('div');
    footer.innerHTML = widgetFooter;
    document.getElementById(mapElement).appendChild(footer.firstChild);
  }

  /**
   * The threat map object
   */
  const map = L.map(mapElement, {
    center: mapCenter,
    zoomSnap: 0.1,
    zoom: zoomLevel,
    minZoom: zoomLevel,
    maxZoom: 5,
    maxBounds: boundsLimit,
    attributionControl: false,
    zoomControl: false,
    trackResize: !isMobile,
    //dragging: !isMobile,
    //touchZoom: !isMobile,
    //scrollWheelZoom: !isMobile
  });

  state.dragging = true;

  /**
   * Prevent any dragging past the bounds maximum
   */
  map.on("drag", function () {
    map.panInsideBounds(boundsLimit, { animate: false });
  });

  if (config.showAttribution) L.control.attribution({ prefix: "" }).addTo(map);

  /**
   * Add zoom control but move to the right
   */
  if (config.showZoomControl && !isMobile) {
    L.control
      .zoom({
        position: "topright",
      })
      .addTo(map);
  }

  /**
   * Fullscreen map control :
   *
   * Addition of button to control full screen
   */
  let fullscreenControl = false;
  if (config.showFullScreenControl && !isMobile) {
    fullscreenControl = L.control
      .fullscreen({
        position: "topright",
        //title: 'Show me the fullscreen !', // change the title of the button, default Full Screen
        //titleCancel: 'Exit fullscreen mode', // change the title of the button when fullscreen is on, default Exit Full Screen
        //content: null, // change the content of the button, can be HTML, default null
        //forceSeparateButton: true, // force seperate button to detach from zoom buttons, default false
        //forcePseudoFullscreen: true, // force use of pseudo full screen even if full screen API is available, default false
        //fullscreenElement: false // Dom element to render in full screen, false by default, fallback to map._container
      })
      .addTo(map);
  }

  /**
   * Fullscreen map control :
   *
   * Capture fullscreen change event to control gesturecontrol
   */
  map.on("enterFullscreen", function () {
    // enable dragging
    map.dragging.enable();
  });
  map.on("exitFullscreen", function () {
    // disable dragging
    if (!state.dragging) map.dragging.disable();
  });

  /**
   * Specify layers for CC and Bot (and perhaps Bot families)
   */
  const featureGroups = {};
  const layerControl = L.control.layers(null, featureGroups);

  if (config.showLayerControl && !isMobile) {
    layerControl.addTo(map);
  }

  /**
   * And the markers that will be placed onto those layers
   */
  const markers = {};

  /**
   * Popup slider control with Slick,
   * @TODO to be removed because of jquery dependency
   */
  const initSlick = () => {
    if (
      $(".leaflet-popup-pane #map-marker-slider").hasClass("slick-initialized")
    ) {
      return;
    }

    $(".leaflet-popup-pane #map-marker-slider").slick({
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 100,
      vertical: true,
      arrows: false,
    });
  };

  const destroySlick = () => {
    if (
      $(".leaflet-popup-pane #map-marker-slider").hasClass("slick-initialized")
    ) {
      $(".leaflet-popup-pane #map-marker-slider").slick("unslick");
    }
  };

  /**
 * Leaving this in as the popupclose / popupopen events seem a little flakey
 
MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
const obs = new MutationObserver(function(mutations, observer) {
    for(let i=0; i<mutations.length; ++i) {
        for(let j=0; j<mutations[i].addedNodes.length; ++j) {
            if(mutations[i].addedNodes[j].classList.contains('leaflet-popup')) {
              initSlick();
            }
        }
    }
});

// have the observer observe foo for changes in children
obs.observe($(".leaflet-popup-pane").get(0), {
  childList: true
});
*
*/

  /**
   * Horrible reset button listener
   */

  $(".js-map-reset").click((e) => {
    map.setView(defaultMapCenter, zoomLevel);
    if ("Bot" in featureGroups) {
      featureGroups["Bot"].clearLayers();
    }
    map.invalidateSize();
  });

  /**
   *
   * Listen for orientation changes
   */
  function checkOrientation() {
    // landscape
    if (window.orientation == 90 || window.orientation == -90) {
      // disable scroll and show full screen
      map.dragging.disable();
      map.touchZoom.disable();
      $(".js-map-reset").addClass("hidden");
      state.dragging = false;
    }
    // portrait
    else {
      // enable scroll and hide full screen
      map.dragging.enable();
      map.touchZoom.enable();
      $(".js-map-reset").removeClass("hidden");
      state.dragging = true;
    }
  }
  window.addEventListener("orientationchange", checkOrientation, false);

  /**
   * Marker object
   */
  const BotMarker = function (plot) {
    let latLng = [plot.lat, plot.lng],
      iconClass = plot.cc == "1" ? "cc" : "bot",
      now = new Date(),
      expire = now.setSeconds(now.getSeconds() + 20),
      fadeOut = expire - 10000;

    let iconTypes = {
      bot: {
        radius: limitRadius(plot.count),
        botId: plot.id,
        botCount: plot.active_bots,
        className: config.showAnimation
          ? "map-marker bot animated"
          : "map-marker bot",
        color: config.botnetFillColor,
        weight: 1,
        fillColor: config.botnetFillColor,
        fillOpacity: 0.5,
      },
      cc: {
        radius: markerDefaultSize,
        className: config.showAnimation
          ? "map-marker cc animated"
          : "map-marker cc",
        color: config.ccFillColor,
        weight: 1,
        fillColor: config.ccFillColor,
        fillOpacity: 0.5,
      },
    };

    /**
     * Instantiate Leaflet marker
     */
    this.marker = L.circleMarker(latLng, iconTypes[iconClass]);

    /**
     * Tooltip to Marker
     */
    if (config.showTooltips && !isMobile) {
      let plural = plot.active_bots > 1 ? "s" : "";
      let tooltipText =
        iconClass == "cc"
          ? "Command & Control server"
          : plot.active_bots +
            " botnet" +
            plural +
            " active at this location. Click circle to find out more";
      this.marker.bindTooltip(tooltipText, { className: "bot-tooltip" });
    }

    /**
     * Control attributes for this marker (only botnet, not CC)
     */
    if (iconClass == "cc") return;

    this.expiry = expire;
    this.fadeOut = fadeOut;
    this.id = plot.id;

    function limitRadius(radius) {
      // All mobile markers are the same size
      if (isMobile) return markerMaxSize;

      let baseline = markerDefaultSize;
      let converted = 0;
      converted = baseline + Math.ceil(radius / 100);
      if (radius < 200) converted = baseline + Math.ceil(radius / 10);

      if (converted > markerMaxSize) converted = markerMaxSize;

      if (config.debug && config.debugLevel > 2)
        console.log("Marker " + radius + " converted to " + converted);

      return converted;
    }
  };

  var gl = L.mapboxGL({
    attribution:
      '<a href="https://www.spamhaus.org">Data from the Spamhaus Project</a>',
    style:
      "https://widget.spamhaus.com/tiles/" + config._style + ".json",
  }).addTo(map);

  function expireMarkers() {
    var now = new Date();

    for (var i in markers) {
      if (markers[i].fadeOut < now) {
        if (typeof(markers[i].marker._path) != 'undefined' && markers[i].marker._path != null)
        {
          L.DomUtil.addClass(markers[i].marker._path, "expiring");
        }
      }

      if (markers[i].expiry < now) {
        // remove tooltip
        markers[i].marker.unbindTooltip();

        // close and remove popup
        markers[i].marker.closePopup();
        markers[i].marker.unbindPopup();
        markers[i].marker.off();

        //markers[i].marker.remove(); // this doesn't seem to work
        featureGroups["Bot"].removeLayer(markers[i].marker);
        delete markers[i];
      }
    }
  }

  function loadPoints() {
    if (!("Bot" in featureGroups)) {
      featureGroups["Bot"] = L.featureGroup().addTo(map);
      layerControl.addOverlay(featureGroups["Bot"], "Show botnet activity");

      if (config.showPopups) {
        featureGroups["Bot"].on("click", function (e) {
          /**
           * Popup with async data request - applied at featureGroup level
           */

          let id = e.layer.options.botId;
          if (!id) return false;

          const url = "https://widget.spamhaustech.com/data/location/" + id;
          $.get(url).done((data) => {
            // get content from templates
            let slides = "";
            for (let i = 0; i < data.bots.length; i++) {
              slides += L.Util.template(slideTemplate, data.bots[i]);
            }

            let content = L.Util.template(popupTemplate, {
              bot_count: data.bot_count,
              plural: data.bot_count > 1 ? "s" : "",
              full_count: data.full_count,
              slides: slides,
            });
            e.layer
              .on("popupclose", function (popup) {
                destroySlick();
              })
              .on("popupopen", function (popup) {
                initSlick();
              })
              .bindPopup(content, { closeButton: false })
              .openPopup();
          });
        });
      } // if using popups
    }

    expireMarkers();

    $.get("https://widget.spamhaustech.com/data/", {}, (res, resp) => {
      for (let i = 0, len = res.length; i < len; i++) {
        if (config.debug) {
          console.log("Loading " + res.length + " plots ...");
        }

        // if marker is already on the map, skip
        if (res[i].id in markers) {
          continue;
        }

        // stop loading more markers on mobile
        if (isMobile && i > mobileMarkerLoadLimit) {
          break;
        }

        let marker = new BotMarker(res[i]);
        markers[res[i].id] = marker;

        (function (marker) {
          let rInt = Math.round(Math.random() * 2000);
          window.setTimeout(function () {
            if ("Bot" in featureGroups)
              featureGroups["Bot"].addLayer(marker.marker);
          }, rInt);
        })(marker);
      }
    });

    plotCount = Object.keys(markers).length;
  }

  /**
   * Load CC botnets, only on load
   */
  function loadCC() {
    featureGroups["CC"] = L.featureGroup().addTo(map);
    layerControl.addOverlay(featureGroups["CC"], "Show C&C servers");

    $.get("https://widget.spamhaustech.com/data/cc", {}, (res, resp) => {
      for (let i = 0, len = res.length; i < len; i++) {
        let marker = new BotMarker(res[i]);
        featureGroups["CC"].addLayer(marker.marker);
      }
    });
  }

  loadCC();

  /**
   * Main threat map data load controller
   * Loads batch of data at randomized intervals
   */
  (function loop() {
    loadCount++;

    // increase minimum reload time if things are gettin busy
    let min =
        loadCount % 10 == 0 || plotCount > markerSlowThreshold
          ? minBusyPlotRefreshTime
          : minPlotRefreshTime,
      timeOut = Math.round(Math.random() * 2500) + min;

    if (config.debug) {
      console.log("Load count:" + loadCount);
      console.log("Refresh interval: " + timeOut);
      console.log("Plot count: " + plotCount);
    }

    setTimeout(function () {
      loadPoints();
      loop();
    }, timeOut);

    /**
     * This is an egregious hack
     * Seems for low zoom levels
     *
     */
    if (isMobile) {
      map.invalidateSize();
      map.setCenter(defaultMapCenter);
    }
  })();

  checkOrientation();
};

export default mapInstance;
