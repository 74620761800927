(function() {
  function ready(fn) {
    if (document.readyState != "loading") {
      fn();
    } else {
      document.addEventListener("DOMContentLoaded", fn);
    }
  }

  const populateTable = (table, data) => {
    let i = 1;
    for (let element of data) {
      let row = table.insertRow();

      let th = document.createElement("th");
      let text = document.createTextNode(i);
      th.appendChild(text);
      row.appendChild(th);

      for (let key in element) {
        let cell = row.insertCell();
        let text = document.createTextNode(element[key]);
        cell.appendChild(text);
      }

      i++;
    }

    // append the copyright
    let row = table.insertRow();
    let cell = row.insertCell();
    cell.colSpan = 4;
    let text = document
      .createRange()
      .createContextualFragment(
        'To find out more about threat intelligence from Spamhaus, visit <a href="https://www.spamhaus.org/statistics/botnet-cc/">spamhaus.org</a>'
      );
    cell.appendChild(text);
  };

  $(function() {
    /* get table data */
    $.get(DATA_URL + "botcountries/").done(data => {
      let countryTable = document.querySelector("#js-table-countries");
      populateTable(countryTable, data);
    });

    $.get(DATA_URL + "botisps/").done(data => {
      let ispTable = document.querySelector("#js-table-isp");
      populateTable(ispTable, data);
    });

    /* mouseover and then collapse tables */
    $(".threat-map-table-collapse")
      .mouseenter(function() {
        $(this).removeClass("table-collapsed");
      })
      .mouseleave(function() {
        $(this).addClass("table-collapsed");
      });

    setTimeout(() => {
      $(".threat-map-table-collapse").addClass("table-collapsed");
    }, 3000);
  });
})();
